

import React, { createContext, useContext } from 'react';
import useMeetings from '../hooks/useMeetings';

const MeetingsContext = createContext({});

export const MeetingsProvider = ({ websocketUrl, children }) => {
  const meetings = useMeetings(websocketUrl);
  return (
    <MeetingsContext.Provider value={meetings}>
      {children}
    </MeetingsContext.Provider>
  );
};

export const useMeetingsContext = () => useContext(MeetingsContext);
