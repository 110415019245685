import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import './Button.module.css';

export default function Button({
  type = 'button',
  children,
  className = '',
  href,
  ...rest
}) {
  if (href) {
    return (
      <a
        href={href}
        className={clsx('button', className)}
      >
        {children}
      </a>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={clsx('button', className)}
      {...rest}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
};
