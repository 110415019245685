// Icon.js
import { GREYHOUND, Harness, Thoroughbred } from './icons';

import PropTypes from 'prop-types';

const icons = {
  GREYHOUND,
  Harness,
  Thoroughbred,
};

export default function Icon({ name }) {
  const IconComponent = icons[name];
  if (!IconComponent) {
    return null; // fallback in case an invalid name is provided
  }
  return <IconComponent />;
}

Icon.propTypes = {
  name: PropTypes.oneOf(['GREYHOUND', 'Harness', 'Thoroughbred']),
};
