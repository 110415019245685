import React, { useEffect } from 'react';
import RacingCalendar from './components/RacingCalendar/RacingCalendar';
import { MeetingsProvider } from './contexts/MeetingsContext';
import './App.css';
import './styles/variables.css';
import './styles/mediaqueries.css';
import './styles/globals.css';

function App() {
  // Access the settings from the localized script or use default values
  const {
    buttonColor = '#0b143b',
    fontColor = '#d8be5b',
    url = 'https://wager.suffolkotb.com/wager',
    websocketUrl = 'https://gt-qc.testbetmservices.com/api/falcon/ws/'
  } = window.nextToJumpSettings || {};

  // Apply CSS variables to the root element
  useEffect(() => {
    document.documentElement.style.setProperty('--button-color', buttonColor);
    document.documentElement.style.setProperty('--font-color', fontColor);
  }, [buttonColor, fontColor]);

  return (
    <MeetingsProvider websocketUrl={websocketUrl}>
      <div className="App">
        <RacingCalendar buttonColor={buttonColor} fontColor={fontColor} url={url} websocketUrl={websocketUrl}/>
      </div>
    </MeetingsProvider>
  );
}

export default App;
