import { useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';

export function useRaceInfoSubscription(raceId, websocketUrl) {
  const [raceInfo, setRaceInfo] = useState(null);

  useEffect(() => {
    if (!raceId || !websocketUrl) return;

    const sock = new SockJS(websocketUrl);
    const stompClient = new Client({
      webSocketFactory: () => sock,
      reconnectDelay: 5000, // Retry connection after 5 seconds if disconnected
      debug: (str) => console.log(str),
    });

    stompClient.activate(); // Activate the WebSocket connection

    stompClient.onConnect = () => {
      const topicName = `/broker/v2.race.${raceId}.en-US.runners`;

      const subscription = stompClient.subscribe(topicName, (message) => {
        try {
          const parsedMessage = JSON.parse(message.body);
          const runnersInfo = parsedMessage.runners.map(runner => ({
            name: runner.horse.horseName,
            jockey: runner.riderName,
            trainer: runner.horse.trainerName,
            postPosition: runner.postPosition,
            programNumber: runner.programNumber,
            signature: runner.signature,
            scratch: runner.scratch,
            
          }));

          setRaceInfo(runnersInfo); // Update race info with runners data
        } catch (error) {
          console.error('Failed to parse race info message:', error);
        }
      });

      // Unsubscribe and deactivate client when component unmounts or raceId changes
      return () => {
        subscription.unsubscribe(); // Unsubscribe from the topic
        stompClient.deactivate(); // Deactivate the WebSocket client
      };
    };

    sock.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      stompClient.deactivate(); // Deactivate the WebSocket client on cleanup
    };
  }, [raceId, websocketUrl]); // Re-run effect when raceId or websocketUrl changes

  return raceInfo;
}
