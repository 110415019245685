import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import Competitor from '../Competitor/Competitor';
import Icon from '../Icon/Icon';
import Countdown from '../Countdown/Countdown';
import styles from './CalendarEvent.module.css';
import { useRaceInfoSubscription } from '../../hooks/useRaceInfoSubscription';

export default function CalendarEvent({
  meeting,
  websocketUrl,
  url,
  buttonColor,
  fontColor,
}) {
  const [selectedRace, setSelectedRace] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [subscribedRaceId, setSubscribedRaceId] = useState(null);
  
  // Subscribe to race info when expanded and race is selected
  const raceInfo = useRaceInfoSubscription(subscribedRaceId, websocketUrl);

  useEffect(() => {
    if (expanded && meeting.raceCards[0].races.length > 0) {
      // Subscribe to the first race by default when expanded
      const firstRace = meeting.raceCards[0].nextRace;
      setSelectedRace(firstRace);
      setSubscribedRaceId(firstRace.id);
    }
  }, [expanded, meeting]);

  const handleRaceClick = (race) => {
    setSelectedRace(race);
    setSubscribedRaceId(race.id); // Update subscription to the new race
  };

  const handleExpand = () => {
    setExpanded((prev) => !prev);
    if (!expanded) {
      setSubscribedRaceId(meeting.raceCards[0].races[0]?.id);
    }
  };

  const raceType = meeting.type

  return (
    <div>
      <button
        type="button"
        onClick={handleExpand}
        className={styles.meetingButton}
        style={{ backgroundColor: buttonColor, color: fontColor }}
      >
        <Icon name={raceType} className = {styles.icon_horse}/>
        <span className={styles.track}>
          {meeting.raceCards[0].name}{' '}
          {meeting.raceCards[0].nextRace.postTime
            ? dayjs(meeting.raceCards[0].nextRace.postTime).tz().format('h:mm a')
            : 'N/A'}
        </span>
      </button>
      {expanded && (
        <div className={styles.content}>
          <div className={styles.races}>
            {meeting.raceCards[0].races.map((race) => {
              const selected = selectedRace?.id === race.id;
              const closed = race.status === "OFFICIALLY_COMPLETED" ? true : false;
              return (
                <button
                  type="button"
                  key={race.id}
                  className={clsx(styles.raceButton, {
                    [styles.closed]: closed,
                    [styles.selected]: selected
                  })}
                  onClick={() => handleRaceClick(race)}
                  style={
                    selected
                      ? {
                          backgroundColor: buttonColor,
                          color: fontColor,
                          borderColor: buttonColor,
                        }
                      : {}
                  }
                >
                  {race.raceNumber}
                </button>
              );
            })}
          </div>
          {selectedRace && (
            <div>
              <div className={styles.titleContainer}>
                <h2>
                  {meeting.raceCards[0].name} R{selectedRace.raceNumber}
                </h2>
                {(selectedRace.id === meeting.raceCards[0].nextRace.id )? dayjs(meeting.raceCards[0].nextRace.postTime).tz().format('h:mm a'): null}
              </div>
              <ul className={styles.competitors}>
                {raceInfo && raceInfo.map(competitor => (
                  <li key={competitor.name} className={styles.competitor}>
                    <Competitor competitor={competitor} showTrainer type={raceType}>
                      {(url && (selectedRace.status !== 'OFFICIALLY_COMPLETED')) ? (
                        <button
                        type="button"
                        onClick={() => window.open(url, '_blank')}
                        className={styles.ctaButton}
                        > Bet now </button>
                      ) : null}
                    </Competitor>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

CalendarEvent.propTypes = {
  meeting: PropTypes.shape().isRequired,
  url: PropTypes.string,
  fontColor: PropTypes.string,
  buttonColor: PropTypes.string,
};
