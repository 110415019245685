import { useContext } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Container from '../Container/Container';
import Title from '../Title/Title';
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import styles from './RacingCalendar.module.css';
import CalendarEvent from './CalendarEvent';
import StyledMarkdown from '../Markdown/StyledMarkdown';

import { useMeetingsContext } from '../../contexts/MeetingsContext';

// Extend dayjs with the utc and timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export default function RacingCalendar({
  location,
  title,
  websocketUrl,
  url,
  description,
  buttonColor,
  fontColor,
  blacklistedTracks = [],
}) {
  const params = new URLSearchParams(location?.search);
  const date = params.get('date');

  const [selectedDate] = useState(
    date ? dayjs(date).tz(dayjs.tz.guess()) : dayjs().tz(dayjs.tz.guess())
  );
  
  const [showThoroughbred, setShowThoroughbred] = useState(true);
  const [showHarness, setShowHarness] = useState(true);
  const meetings = useMeetingsContext();

  
  const filteredMeetings = meetings?.filter((meeting) => {
    const isThoroughbred = showThoroughbred && meeting.type === "Thoroughbred";
    const isHarness = showHarness && meeting.type === "Harness";

    return (isThoroughbred || isHarness) && 
           !blacklistedTracks?.some((track) => track.name === meeting.track.name);
  }) || [];

  return (
    <Container>
      <Title>{title}</Title>
      <div>
        <div>
          <div className={styles.messageContainer}>
            {description ? <StyledMarkdown description={description} /> : null}
          </div>
        </div>
        <div className={styles.header}>
          <h3 className={styles.title}>
            {selectedDate.format('dddd MMMM DD, YYYY')}
          </h3>
          <div className={styles.filters}>
            <Button
              className={styles.filterButton}
              onClick={() => setShowThoroughbred((prev) => !prev)}
              style={
                showThoroughbred
                  ? { backgroundColor: buttonColor, color: fontColor, fill: fontColor }
                  : {}
              }
            ><Icon name={'Thoroughbred'} className = {styles.icon_horse}/>
            </Button>
            <Button
              className={styles.filterButton}
              onClick={() => setShowHarness((prev) => !prev)}
              style={
                showHarness
                  ? { backgroundColor: buttonColor, color: fontColor, fill: fontColor }
                  : {}
              }
            ><Icon name={'Harness'} className = {styles.icon_horse}/>
            </Button>
          </div>
        </div>
        {filteredMeetings.length === 0 ? (
          <span>No data available yet. Try clicking on horse icons.</span>
        ) : (
          <ul className={styles.list}>
            {filteredMeetings
              .map((meeting) => (
                <li key={meeting.id} className={styles.listItem}>
                  <CalendarEvent
                    meeting={meeting}
                    websocketUrl={websocketUrl}
                    url={url}
                    fontColor={fontColor}
                    buttonColor={buttonColor}
                  />
                </li>
              ))}
          </ul>
        )}
      </div>
    </Container>
  );
}

RacingCalendar.propTypes = {
  location: PropTypes.shape(),
  title: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  buttonColor: PropTypes.string,
  fontColor: PropTypes.string,
  blacklistedTracks: PropTypes.arrayOf(PropTypes.string),
};
