import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './ProgramNumber.css';

const backgroundTypes = {
    PLAIN: 'PLAIN',
    HALVED: 'HALVED',
    STRIPES: 'STRIPES',
    DIAGONAL: 'DIAGONAL'
};

const getProgramNumberStyles = (backgroundType, textColor, backgroundColors) => {
    const styleObject = {
        color: textColor
    };

    switch (backgroundType) {
        case backgroundTypes.PLAIN:
            styleObject.backgroundColor = backgroundColors[0];
            return styleObject;
        case backgroundTypes.HALVED:
            styleObject.background = `linear-gradient(to right, ${backgroundColors[1]} 20%, ${backgroundColors[0]} 20%, ${backgroundColors[0]} 80%, ${backgroundColors[1]} 80%)`;
            return styleObject;
        case backgroundTypes.STRIPES:
            styleObject.background = `linear-gradient(to bottom, ${backgroundColors[0]} 0%, ${backgroundColors[0]} 25%, ${backgroundColors[1]} 25%, ${backgroundColors[1]} 50%, ${backgroundColors[0]} 50% , ${backgroundColors[0]} 75% , ${backgroundColors[1]} 75%)`;
            return styleObject;
        case backgroundTypes.DIAGONAL:
            styleObject.background = `linear-gradient(135deg, ${backgroundColors[0]} 50%, ${backgroundColors[1]} 50%)`;
            return styleObject;
        default:
            return styleObject;
    }
};

const ProgramNumber = ({programNumber, scratched, title, signature, className}) => {
    const {textColor, backgroundColors, backgroundType} = signature;
    const styleObject = getProgramNumberStyles(backgroundType, textColor, backgroundColors);

    return (
        <div className={cx('colorful-index-wrapper', className)}>
            {
                scratched && <div className='scratched' style={{borderColor: textColor}} />
            }
            <div className='colorful-index' style={styleObject}>
                {programNumber === '0' ? '-' : programNumber}
            </div>
            {title && <div className='tooltip-text'>
                {title}
            </div>}
        </div>
    );
};

ProgramNumber.propTypes = {
    programNumber: PropTypes.string.isRequired,
    scratched: PropTypes.bool,
    title: PropTypes.string,
    signature: PropTypes.shape({
        textColor: PropTypes.string.isRequired,
        backgroundColors: PropTypes.arrayOf(PropTypes.string).isRequired,
        backgroundType: PropTypes.string.isRequired
    }),
    className: PropTypes.string
};

export default ProgramNumber;
