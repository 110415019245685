import { useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';

export default function useMeetings(websocketUrl) {
  const [meetings, setMeetings] = useState([]);

  useEffect(() => {
    if (!websocketUrl) {
      console.error('WebSocket URL is not provided');
      return;
    }

    const sock = new SockJS(websocketUrl);
    const stompClient = new Client({
      webSocketFactory: () => sock,
      reconnectDelay: 5000,
      debug: (str) => console.log(str),
    });
    stompClient.activate();

    stompClient.onConnect = (frame) => {
      // console.log('Connected:', frame);

      const topicName = '/broker/v2.tracks.en-US';

      stompClient.subscribe(topicName, (message) => {
        try {
          const parsedMessage = JSON.parse(message.body);

          // Set the initial meetings data
          setMeetings(parsedMessage);

        } catch (error) {
          console.error('Failed to parse meetings message:', error);
        }
      });
    };

    sock.onopen = function () {
      console.log('WebSocket connection opened');
    };

    sock.onclose = function () {
      console.log('WebSocket connection closed');
    };

    return () => {
      // Clean up on component unmount
      stompClient.deactivate();
    };
  }, [websocketUrl]); // Re-run effect if websocketUrl changes

  return meetings;
}
