import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './StyledMarkdown.css';

export default function StyledMarkdown({ description }) {
  return (
    <ReactMarkdown className={styles.markdown} linkTarget="_blank">
      {description}
    </ReactMarkdown>
  );
}

StyledMarkdown.propTypes = {
  description: PropTypes.string,
};
